export const statuses = () => {
    return {
        ACTUAL: useI18n().t('actual'),
        FINISHED: useI18n().t('finished'),
    }
}

export type StatusCode = keyof typeof statuses

export interface Complex {
    id: number
    slug: string
    complex_name: string
    address: string
    phone: string
    status: {
        id: number
        status: StatusCode
        title: string
        name: string
    }
    procent_finish: string
    price_start_from: string
    start_date: string
    finish_date: string
    redirect_url: string | null
    redirect_type: string | null
    block: string | null
    apartments: string | null
    underground_parking: string | null
    land_parking: string | null
    disponible_apartments: string | null
    sector: string | null
    cover_photo: string
    second_cover_photo: string
}

